import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchKrs } from "../../api";
import Loading from "../../components/Loading";
import { getHari, getGrade, getOrigin } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState } from "../../storages/auth";
import LoginAs from "../../components/layouts/LoginAs";

const Krs = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => setTitle("Jadwal Perkuliahan"), [setTitle]);

  const [items, setItems] = useState({});
  const [page] = useState("all");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchKrs(
        page,
        token,
        `with_mhs=1&with_mhs_nilai_akhir=1&with_mhs_nilai_ip=1&order_by_hari=1&generate_nilai=1`
      );
      if (res.data && res.data.krs) {
        setItems(res.data.krs);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoaded) {
    return (
      <>
        <div className="bg-white shadow rounded-3 mx-2 pt-3">
          <LoginAs />
          <div className="row g-0">
            <div className="col-lg-12 mt-3">
              <div className="nice-background shadow-nice rounded-3 p-0">
                <div className="custom-hue">
                  <div className="score-background rounded-top-3 shadow-nice p-4">
                    <p className="my-0 text-center text-white text-shadow fw-semibold fst-italic fs-5">YOUR <span className="text-warning fs-5">SECOND HOME</span></p>
                  </div>
                    <div className="rounded-3 py-4">
                      <div className="text-center py-4">
                        <h5 className="text-white">IPK Anda :</h5>
                        <div className="">
                          <h5 className="my-0 px-5 display-4 fw-semibold text-warning text-shadow">
                            {" "}
                            {items &&
                              items[0] &&
                              items[0].mhs &&
                              items[0].mhs.nilai_akhir ?
                              items[0].mhs.nilai_akhir.ipk : '0'}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="btn-group w-100">
                      <button
                        className="w-100 border-0 py-2 btn-primary-3 rounded-start-3"
                        type="button"
                        onClick={() =>
                          window.open(
                            getOrigin() +
                              "/storage/akademik/kalender-akademik.pdf"
                          )
                        }
                      >
                        <span className="d-flex justify-content-center align-items-center small">
                          <i className="fa fa-fw fa-calendar-days me-2" /> Kalender Akademik
                        </span>
                      </button>
                      <button
                        className="w-100 border-0 py-2 btn-primary-3 rounded-end-3"
                        type="button"
                        onClick={() =>
                          window.open(
                            getOrigin() +
                              "/files/sia/akademik/BUKU-PEDOMAN-AKADEMIK-DAN-KEMAHASISWAAN-2024.pdf"
                          )
                        }
                      >
                        <span className="d-flex justify-content-center align-items-center small">
                          <i className="fa fa-fw fa-calendar-days me-2" /> Buku Pedoman
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div className="rounded-4 mx-3 p-3">
          <h1 className="fw-semibold text-center py-2 my-0 fs-5">Data Kelas</h1>
        </div>

        {items.length > 0 ? (
          <>
            <CardKrs items={items} semester={4} />
            <CardKrs items={items} semester={3} />
            <CardKrs items={items} semester={2} />
            <CardKrs items={items} semester={1} />
          </>
        ) : (
          <div className="bg-white shadow rounded-3 mx-2 px-3">
            <div className="row g-4 rounded-4 row-size pt-0 pb-4">
              <div className="text-center p-5">
                <i className="fa fa-fw fa-circle-exclamation text-secondary fs-3 mb-2 pt-5"></i>
                <p className="my-0 text-secondary small pb-5">
                  Saat ini data perkuliahan masih kosong.
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <Loading text="Loading. . ." />
    );
  }
};

const CardKrs = (props) => {
  const { items, semester } = props;

  const [collapseCard, setCollapseCard] = useState(false);

  const toggleCollapseCard = () => {
    setCollapseCard(!collapseCard);
  };

  if (
    items &&
    items.length > 0 &&
    items.filter(
      (i) => i.kelas && parseInt(i.kelas.semester) === parseInt(semester)
    ).length > 0
  ) {
    return (
      <>
        <div className="bg-white shadow rounded-3 mx-2 mb-3">
          <button
            className="btn btn-primary-4 border-bottom-warning text-white shadow-nice rounded-3 p-3 w-100 d-flex justify-content-between align-items-center"
            type="button"
            onClick={toggleCollapseCard}
            aria-expanded="false"
          >
            <div>
              <h6 className="my-0 fw-semibold text-warning text-start">
                Semester {semester}
              </h6>
              {!collapseCard && (
                <p className="my-0 text-gray-500 text-sm px-1 pt-2 pb-1">
                  Klik untuk melihat jadwal. . .
                </p>
              )}
              {collapseCard && (
                <div className="rounded-3 pt-2">
                  <span className="border border-secondary rounded-start text-white px-3 py-1 text-sm">
                    IP :{" "}
                    {items &&
                      items[0] &&
                      items[0].mhs &&
                      items[0].mhs.nilai_ip &&
                      items[0].mhs.nilai_ip.length > 0 &&
                      items[0].mhs.nilai_ip
                        .filter(
                          (ni) => parseInt(ni.semester) === parseInt(semester)
                        )
                        .map((ni) => ni.nilai)}
                  </span>
                  <span className="border-start-0 border border-secondary rounded-end text-white px-3 py-1 text-sm">
                    {
                      items
                        .filter(
                          (kls) => parseInt(kls.semester) === parseInt(semester)
                        )
                        .map((kls) => kls.items).length
                    }{" "}
                    Kelas
                  </span>
                </div>
              )}
            </div>
            {collapseCard === true ? (
              <i className="fs-5 fa fa-fw fa-caret-up" />
            ) : (
              <i className="fs-5 fa fa-fw fa-caret-down" />
            )}
          </button>

          <div
            className={`collapse-nice ${collapseCard ? "show-nice" : ""} px-3`}
          >
            <div className="row g-4 rounded-4 row-size pt-0 pb-4">
              {items &&
                items.length > 0 &&
                items
                  .filter(
                    (i) =>
                      i.kelas &&
                      parseInt(i.kelas.semester) === parseInt(semester)
                  )
                  .map((i, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="col-lg-6">
                          <div
                            // className={`rounded-3 shadow-nice p-4 border-bottom-warning h-100 ${
                            //   i.matkul &&
                            //   parseInt(i.matkul.jenis_matkul) === 1 ?
                            //   "bg-gradient-primary" : i.matkul && parseInt(i.matkul.jenis_matkul) === 2 ?
                            //   "bg-gradient-success" : "bg-gradient-primary"
                            // }`}
                            className="rounded-3 bg-gradient-primary shadow-nice p-4 border-bottom-warning h-100"
                          >
                            <div className="d-flex justify-content-between align-items-start mb-2">
                              <div>
                                <h5 className="text-warning fw-semibold fst-italic">
                                  {i.kelas && i.kelas.kode}
                                </h5>
                                <h6 className="my-0 text-gray-500 fw-semibold text-sm">
                                  <i className="fa fa-fw fa-thumbtack text-sm me-1" />
                                  {i.matkul &&
                                  parseInt(i.matkul.jenis_matkul) === 1
                                    ? "PRAKTEK"
                                    : i.matkul &&
                                      parseInt(i.matkul.jenis_matkul) === 2
                                    ? "TEORI"
                                    : "-"}
                                </h6>
                              </div>
                              <div>
                                {i.jamkul.hari ? (
                                  <h6 className="text-white fw-semibold my-0">
                                    {getHari(i.jamkul.hari)}
                                  </h6>
                                ) : (
                                  <h5 className="text-gray-700 fw-semibold fs-3 my-0 bg-dark rounded fa-fw shadow-nice">
                                    -
                                  </h5>
                                )}
                              </div>
                            </div>

                            <table className="table table-sm table-borderless">
                              <tbody>
                                {/* <tr key={index}>
                                  <td className="bg-none small text-gray-500">
                                    No
                                  </td>
                                  <td className="bg-none small text-white">
                                    :
                                  </td>
                                  <td className="bg-none small text-white">
                                  {index + 1}
                                  </td>
                                </tr> */}
                                <tr>
                                  <td className="bg-none small text-gray-500">
                                    Nama Mata Kuliah
                                  </td>
                                  <td className="bg-none small text-white">
                                    :
                                  </td>
                                  <td className="bg-none small text-white">
                                    {i.matkul && i.matkul.nama}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="bg-none small text-gray-500">
                                    Dosen
                                  </td>
                                  <td className="bg-none small text-white">
                                    :
                                  </td>
                                  <td className="bg-none small text-white">
                                    {i.dosen ? i.dosen.nama : '-'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="bg-none small text-gray-500">
                                    Ruangan
                                  </td>
                                  <td className="bg-none small text-white">
                                    :
                                  </td>
                                  <td className="bg-none small text-white">
                                    {i.ruangan && i.ruangan.nama}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="bg-none small text-gray-500">
                                    Jam
                                  </td>
                                  <td className="bg-none small text-white">
                                    :
                                  </td>
                                  <td className="bg-none small text-white">
                                    {/* {i.jamkul &&
                                      `Hari ${getHari(i.jamkul.hari)} (${
                                        i.jamkul.dari
                                      } s/d ${i.jamkul.sampai})`} */}
                                    {i.jamkul &&
                                      `${getHari(i.jamkul.hari)}, ${
                                        i.jamkul.dari
                                      } - ${i.jamkul.sampai}`}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="bg-none small text-gray-500">
                                    Modul & Bahan Ajar
                                  </td>
                                  <td className="bg-none small text-white">
                                    :
                                  </td>
                                  <td className="bg-none">
                                    <button
                                      className="btn btn-sm btn-primary-2 rounded-5 shadow text-sm px-3 py-custom-1"
                                      onClick={() =>
                                        window.open(
                                          i.matkul && i.matkul.bahan_ajar_link
                                        )
                                      }
                                    >
                                      <i className="fa fa-fw fa-book-open me-2 text-sm" />
                                      buka disini
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="bg-none small text-gray-500">
                                    Group WA
                                  </td>
                                  <td className="bg-none small text-white">
                                    :
                                  </td>
                                  <td className="bg-none">
                                    {i.kelas && i.kelas.group_whatsapp ?
                                    <button
                                      className="btn btn-sm btn-success-2 rounded-5 shadow text-sm px-3 py-custom-1"
                                      onClick={() => {
                                        window.open(
                                          i.kelas && i.kelas.group_whatsapp
                                        );
                                      }}
                                    >
                                      <i className="bi bi-whatsapp me-1 text-sm"></i>{" "}
                                      Join group
                                    </button>
                                    :
                                    <button className="btn btn-sm btn-dark border-0 disabled rounded-5 shadow text-sm px-3 py-custom-1">
                                      <i className="bi bi-whatsapp me-1 text-sm"></i>{" "}
                                      Belum tersedia
                                    </button>
                                    }
                                  </td>
                                </tr>
                                <tr className="align-middle">
                                  <td className="bg-none small text-gray-500">
                                    Nilai
                                  </td>
                                  <td className="bg-none small text-white">
                                    :
                                  </td>
                                  <td className="bg-none fw-semibold text-warning text-size-custom">
                                    {i.nilai && i.nilai && i.nilai.grade
                                      ? getGrade(i.nilai.grade)
                                      : "-"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <div className="btn-group shadow w-100">
                              <Link
                                className="btn btn-sm btn-primary-2"
                                to={`/kelas-show/${i.kelas.id}`}
                              >
                                <i className="fa fa-fw fa-eye me-1"></i>
                                Detail
                              </Link>
                              <Link
                                className="btn btn-sm btn-warning-2"
                                to={`/kelas-penilaian-dosen/${
                                  i.kelas && i.kelas.id
                                }`}
                              >
                                <i className="fa fa-fw fa-star me-1"></i>
                                Review dosen
                              </Link>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default Krs;
