import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { showAngsuran, showProfile } from "../../api";
import Loading from "../../components/Loading";
import { formatDate, getCurrency, getOrigin, saAlert } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState, userState } from "../../storages/auth";
import LoginAs from "../../components/layouts/LoginAs";

const Pembayaran = () => {
  const user = useRecoilValue(userState);
  const token = useRecoilValue(tokenState);

  const setTitlte = useSetRecoilState(titleState);
  useEffect(() => setTitlte("Data Pembayaran"), [setTitlte]);

  const [items, setItems] = useState({});
  const [pembayaranOptional, setPembayaranOptional] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showProfile(user.id, token);
        if (res.data) {
          setItems(res.data.mhs);
        }
      } catch (err) {
        console.log(err.data);
      }
    };

    fetchData();

    const fetchDataOptional = async () => {
      try {
        const res = await showAngsuran("mhs_id", token, `mhs_id=${user.id}`);
        if (res.data) {
          res.data.angsuran_optional &&
            setPembayaranOptional(res.data.angsuran_optional);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchDataOptional();
  }, [user.id, token]);

  const getBayarDpp = () => {
    if (items.biaya) {
      if (items.biaya.tanggal_du_3) {
        return formatDate(items.biaya.tanggal_du_3);
      } else if (items.biaya.tanggal_du_2) {
        return formatDate(items.biaya.tanggal_du_2);
      } else if (items.biaya.tanggal_du_1) {
        return formatDate(items.biaya.tanggal_du_1);
      }
    }
  };

  const getAngsuran = () => {
    if (items.angsuran_1) {
      return getCurrency(items.angsuran_1.angsuran, 0);
    } else if (items.angsuran_2) {
      return getCurrency(items.angsuran_2.angsuran, 0);
    } else if (items.biaya) {
      return items.biaya && getCurrency(items.biaya.angsuran, 0);
    }
  };

  const getDu = () => {
    let du = 0;
    // if (parseInt(user.angkatan) < 2022) {
    //   if (user.jenjang === "D1") {
    //     du = 4000000;
    //   } else if (user.jenjang === "D2") {
    //     du = 5500000;
    //   }
    // } else {
    //   if (user.jenjang === "D1") {
    //     du = 4500000;
    //   } else if (user.jenjang === "D2") {
    //     du = 5500000;
    //   }
    // }
    if (items.biaya) {
      let du_1 = items.biaya.du_1 ? items.biaya.du_1 : 0;
      let du_2 = items.biaya.du_2 ? items.biaya.du_2 : 0;
      let du_3 = items.biaya.du_3 ? items.biaya.du_3 : 0;

      du = parseInt(du_1) + parseInt(du_2) + parseInt(du_3);
    }
    return du;
  };

  const getTotalAngsuran = () => {
    let angsuran = user.jenjang === "D1" ? items.angsuran_1 : items.angsuran_2;

    let total = 0;
    if (angsuran) {
      total =
        (angsuran.pembayaran_1 ? parseInt(angsuran.pembayaran_1) : 0) +
        (angsuran.pembayaran_2 ? parseInt(angsuran.pembayaran_2) : 0) +
        (angsuran.pembayaran_3 ? parseInt(angsuran.pembayaran_3) : 0) +
        (angsuran.pembayaran_4 ? parseInt(angsuran.pembayaran_4) : 0) +
        (angsuran.pembayaran_5 ? parseInt(angsuran.pembayaran_5) : 0) +
        (angsuran.pembayaran_6 ? parseInt(angsuran.pembayaran_6) : 0) +
        (angsuran.pembayaran_7 ? parseInt(angsuran.pembayaran_7) : 0) +
        (angsuran.pembayaran_8 ? parseInt(angsuran.pembayaran_8) : 0) +
        (angsuran.pembayaran_9 ? parseInt(angsuran.pembayaran_9) : 0) +
        (angsuran.pembayaran_10 ? parseInt(angsuran.pembayaran_10) : 0) +
        (angsuran.pembayaran_11 ? parseInt(angsuran.pembayaran_11) : 0) +
        (angsuran.pembayaran_12 ? parseInt(angsuran.pembayaran_12) : 0) +
        (angsuran.pembayaran_13 ? parseInt(angsuran.pembayaran_13) : 0) +
        (angsuran.pembayaran_14 ? parseInt(angsuran.pembayaran_14) : 0) +
        (angsuran.pembayaran_15 ? parseInt(angsuran.pembayaran_15) : 0) +
        (angsuran.pembayaran_16 ? parseInt(angsuran.pembayaran_16) : 0) +
        (angsuran.pembayaran_17 ? parseInt(angsuran.pembayaran_17) : 0) +
        (angsuran.pembayaran_18 ? parseInt(angsuran.pembayaran_18) : 0) +
        (angsuran.pembayaran_19 ? parseInt(angsuran.pembayaran_19) : 0) +
        (angsuran.pembayaran_20 ? parseInt(angsuran.pembayaran_20) : 0) +
        (angsuran.pembayaran_21 ? parseInt(angsuran.pembayaran_21) : 0) +
        (angsuran.pembayaran_22 ? parseInt(angsuran.pembayaran_22) : 0) +
        (angsuran.pembayaran_23 ? parseInt(angsuran.pembayaran_23) : 0) +
        (angsuran.pembayaran_24 ? parseInt(angsuran.pembayaran_24) : 0);
    }

    let total_2 = 0;
    if (pembayaranOptional.length > 0) {
      total_2 = pembayaranOptional
        .map((po) => po.jumlah)
        .reduce((prev, curr) => parseInt(prev) + parseInt(curr));
    }
    return total + total_2;
  };

  const getSisaAngsuran = () => {
    let sisa = 0;
    if (items.biaya) {
      let total =
        parseInt(items.biaya.biaya_pendidikan || 0) -
        parseInt(items.biaya.subsidi || 0) -
        getDu() -
        getAngsuranOpsional() -
        parseInt(items.biaya.diskon_dpp || 0);
      let dibayar = getTotalAngsuran();
      sisa +=
        total -
        dibayar -
        parseInt(items.biaya.diskon_angsuran ? items.biaya.diskon_angsuran : 0);
    }
    return sisa;
  };

  const getAngsuranOpsional = () => {
    let total = 0;
    
    if (items.angsuran) {
      items.angsuran.map((a) => total += parseInt(a.jumlah));
    }

    return total
  }



  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = () => {
    // Buat elemen textarea sementara
    const textArea = document.createElement('textarea');
    textArea.value = user.no_briva;
    
    // Tambahkan elemen ke dokumen
    document.body.appendChild(textArea);
    
    // Pilih teks di dalam textarea
    textArea.select();
    document.execCommand('copy');

    // Hapus elemen textarea sementara
    document.body.removeChild(textArea);

    // Setel state copied menjadi true
    setCopied(true);
    
    // alert copy
    saAlert("success", "Copy ke clipboard.");
  };
  

  return (
    <>
      {items.id ? (
        <>
          <div className="bg-white shadow rounded-3 mx-2 pt-3">
            <LoginAs />

            <div className="bg-gradient-primary rounded-3 p-0 mt-4">
              <div>
                <div className="row g-0">
                  <div className="col-lg-12">
                    <div className="shadow-nice score-background rounded-top-3 p-4">
                      <div className="row gx-0 gy-2">
                        <div className="col-lg-12">
                          <div className="text-center text-warning border-bottom border-secondary pb-3 fw-semibold fs-5">
                            Detail sistem pembayaran
                          </div>
                          <div className="rounded-bottom pt-3">
                            <p className="my-0 text-white small">
                              Biaya pendidikan - Subsidi - Biaya daftar ulang -
                              Diskon DPP - Angsuran dibayar - Angsuran Opsional - Diskon angsuran ={" "}
                              <span className="text-warning fw-semibold">
                                Sisa angsuran anda
                              </span>
                            </p>

                            <hr className="my-3 border-white" />

                            <p className="my-0 text-white small">
                              {`Rp ${
                                items.biaya &&
                                getCurrency(items.biaya.biaya_pendidikan || 0, 0)
                              }
                              - Rp
                              ${
                                items.biaya &&
                                getCurrency(items.biaya.subsidi || 0, 0)
                              }
                              - Rp
                              ${getCurrency(getDu(), 0)}
                              - Rp
                              ${
                                items.biaya &&
                                getCurrency(items.biaya.diskon_dpp || 0)
                              }
                              - Rp
                              ${getCurrency(getTotalAngsuran(), 0)}
                              - Rp
                              ${getCurrency(getAngsuranOpsional(), 0)}
                              - Rp
                              ${
                                items.biaya && items.biaya.diskon_angsuran
                                  ? getCurrency(items.biaya.diskon_angsuran)
                                  : 0
                              } = Rp `}
                              <span className="text-warning fw-semibold">
                                {getCurrency(getSisaAngsuran(), 0)}
                              </span>
                            </p>

                            <hr className="my-3 border-white" />

                              <div className="d-flex flex-wrap align-items-center">
                                <p className="my-0 text-white small me-2">
                                  Tidak paham dalam melakukan pembayaran? Klik tombol berikut :
                                </p>
                                <button 
                                  className="mobile-w-100 btn btn-sm btn-success-2 shadow"
                                  onClick={() =>
                                    window.open(
                                      getOrigin() +
                                        "/storage/keuangan/cara-pembayaran.pdf"
                                    )
                                  }
                                >
                                  Cara pembayaran
                                </button>
                              </div>

                              <hr className="my-3 border-white" />

                              <div className="d-flex align-items-center">
                                <p className="mb-0 text-white">No Briva : <span className="text-warning fw-semibold">{user.no_briva}</span></p>
                                <button title="Copy to Clipboard" className="btn btn-sm btn-success-2 ms-2" onClick={handleCopyToClipboard}><i className="fa fa-fw fa-clipboard"/></button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3">
                <div className="row g-3">
                  <div className="col-lg-3">
                    <div className="rounded border-0 bg-primary shadow-nice p-3">
                      <div className="text-warning mb-2">Biaya Pendidikan</div>

                      <hr className="mb-2 mt-0 border-light"></hr>

                      <div className="">
                        <table className="table table-sm my-0">
                          <tbody>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Jumlah
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                                {items.biaya.biaya_pendidikan ?
                                  <>Rp. {getCurrency(items.biaya.biaya_pendidikan, 0)}</> 
                                  : '-'}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Tanggal
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                                -
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="rounded border-0 bg-primary shadow-nice p-3">
                      <div className="text-warning mb-2">Subsidi</div>

                      <hr className="mb-2 mt-0 border-light"></hr>

                      <div className="">
                        <table className="table table-sm my-0">
                          <tbody>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Jumlah
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                              {items.biaya.subsidi ?
                                  <>Rp. {getCurrency(items.biaya.subsidi, 0)}</> 
                                  : '-'}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Tanggal
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                                -
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="rounded border-0 bg-primary shadow-nice p-3">
                      <div className="text-warning mb-2">
                        Kelas Malam (Optional)
                      </div>

                      <hr className="mb-2 mt-0 border-light"></hr>

                      <div className="">
                        <table className="table table-sm my-0">
                          <tbody>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Jumlah
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                              {items.biaya.biaya_malam ?
                                  <>Rp. {getCurrency(items.biaya.biaya_malam, 0)}</> 
                                  : '-'}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Tanggal
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                                -
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="rounded border-0 bg-primary shadow-nice p-3">
                      <div className="text-warning mb-2">Biaya Daftar</div>

                      <hr className="mb-2 mt-0 border-light"></hr>

                      <div className="">
                        <table className="table table-sm my-0">
                          <tbody>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Jumlah
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                              {items.biaya.daftar ?
                                  <>Rp. {getCurrency(items.biaya.daftar, 0)}</> 
                                  : '-'}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Tanggal
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                              {items.biaya.daftar ?
                                  <>{formatDate(items.biaya.tanggal_daftar)}</> 
                                  : '-'}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="rounded border-0 bg-primary shadow-nice p-3">
                      <div className="text-warning mb-2">Biaya DPP</div>

                      <hr className="mb-2 mt-0 border-light"></hr>

                      <div className="">
                        <table className="table table-sm my-0">
                          <tbody>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Jumlah
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                              {items.biaya.biaya_dpp ?
                                  <>Rp. {getCurrency(items.biaya.biaya_dpp, 0)}</> 
                                  : '-'}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Tanggal
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                                -
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="rounded border-0 bg-primary shadow-nice p-3">
                      <div className="text-warning mb-2">Diskon DPP</div>

                      <hr className="mb-2 mt-0 border-light"></hr>

                      <div className="">
                        <table className="table table-sm my-0">
                          <tbody>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Jumlah
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                              {items.biaya.diskon_dpp ?
                                  <>Rp. {getCurrency(items.biaya.diskon_dpp, 0)}</> 
                                  : '-'}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Tanggal
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                                -
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="rounded border-0 bg-primary shadow-nice p-3">
                      <div className="text-warning mb-2">Bayar DPP</div>

                      <hr className="mb-2 mt-0 border-light"></hr>

                      <div className="">
                        <table className="table table-sm my-0">
                          <tbody>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Jumlah
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                              {items.biaya && getCurrency(
                                (items.biaya.du_1 ? parseInt(items.biaya.du_1) : 0) +
                                  (items.biaya.du_2 ? parseInt(items.biaya.du_2) : 0) +
                                  (items.biaya.du_3 ? parseInt(items.biaya.du_3) : 0),
                                0
                              )}
                              {/* {items.biaya.du_1 ?
                                  <>Rp. {getCurrency(
                                    (items.biaya ? items.biaya.du_1 : 0) +
                                      (items.biaya ? items.biaya.du_2 : 0) +
                                      (items.biaya ? items.biaya.du_3 : 0),
                                    0\                                  )}</> 
                                  : items.biaya.du_2 ?
                                    <>Rp. {getCurrency(
                                      (items.biaya ? items.biaya.du_1 : 0) +
                                        (items.biaya ? items.biaya.du_2 : 0) +
                                        (items.biaya ? items.biaya.du_3 : 0),
                                      0
                                    )}</> 
                                    : items.biaya.du_3 ?
                                    <>Rp. {getCurrency(
                                      (items.biaya ? items.biaya.du_1 : 0) +
                                        (items.biaya ? items.biaya.du_2 : 0) +
                                        (items.biaya ? items.biaya.du_3 : 0),
                                      0
                                    )}</> 
                                    : '-'} */}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Tanggal
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                                {(getBayarDpp()) ?
                                  <>{getBayarDpp()}</> 
                                  : '-'}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="rounded border-0 bg-primary shadow-nice p-3">
                      <div className="text-warning mb-2">Angsuran</div>

                      <hr className="mb-2 mt-0 border-light"></hr>

                      <div className="">
                        <table className="table table-sm my-0">
                          <tbody>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Jumlah
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                                {(getAngsuran()) ?
                                  <>Rp. {getAngsuran()}</> 
                                  : '-'}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                Tanggal
                              </td>
                              <td className="border-0 text-gray-500 bg-none text-sm">
                                :
                              </td>
                              <td className="border-0 text-white bg-none text-sm">
                                -
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-4 mx-3 p-3">
            <h1 className="fw-semibold text-center py-2 my-0 fs-5">Angsuran</h1>
          </div>

          <div className="mx-0 px-3 pb-3 pt-0">
            <div className="row row-size px-2 gy-3 gx-2">
              {items.angsuran_1 && (
                <>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 1
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_1.pembayaran_1 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.pembayaran_1
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.pembayaran_1, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.denda_1
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.denda_1 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.tanggal_1
                                ?  <>{formatDate(items.angsuran_1.tanggal_1)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 2
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_1.pembayaran_2 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.pembayaran_2
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.pembayaran_2, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.denda_2
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.denda_2 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.tanggal_2
                                ?  <>{formatDate(items.angsuran_1.tanggal_2)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 3
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_1.pembayaran_3 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.pembayaran_3
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.pembayaran_3, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.denda_3
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.denda_3 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.tanggal_3
                                ?  <>{formatDate(items.angsuran_1.tanggal_3)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 4
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_1.pembayaran_4 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.pembayaran_4
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.pembayaran_4, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.denda_4
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.denda_4 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.tanggal_4
                                ?  <>{formatDate(items.angsuran_1.tanggal_4)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 5
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_1.pembayaran_5 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.pembayaran_5
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.pembayaran_5, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.denda_5
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.denda_5 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.tanggal_5
                                ?  <>{formatDate(items.angsuran_1.tanggal_5)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 6
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_1.pembayaran_6 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.pembayaran_6
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.pembayaran_6, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.denda_6
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.denda_6 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.tanggal_6
                                ?  <>{formatDate(items.angsuran_1.tanggal_6)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 7
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_1.pembayaran_7 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.pembayaran_7
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.pembayaran_7, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.denda_7
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.denda_7 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.tanggal_7
                                ?  <>{formatDate(items.angsuran_1.tanggal_7)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 8
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_1.pembayaran_8 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.pembayaran_8
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.pembayaran_8, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.denda_8
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.denda_8 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.tanggal_8
                                ?  <>{formatDate(items.angsuran_1.tanggal_8)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 9
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_1.pembayaran_9 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.pembayaran_9
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.pembayaran_9, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.denda_9
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.denda_9 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.tanggal_9
                                ?  <>{formatDate(items.angsuran_1.tanggal_9)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 10
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_1.pembayaran_10 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.pembayaran_10
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.pembayaran_10, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.denda_10
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.denda_10 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.tanggal_10
                                ?  <>{formatDate(items.angsuran_1.tanggal_10)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 11
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_1.pembayaran_11 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.pembayaran_11
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.pembayaran_11, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.denda_11
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.denda_11 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.tanggal_11
                                ?  <>{formatDate(items.angsuran_1.tanggal_11)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 12
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_1.pembayaran_12 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.pembayaran_12
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.pembayaran_12, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.denda_12
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_1.denda_12 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_1.tanggal_12
                                ?  <>{formatDate(items.angsuran_1.tanggal_12)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                </>
              )}
              {items.angsuran_2 && (
                <>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 1
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_1 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_1
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_1, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_1
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_1 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_1
                                ?  <>{formatDate(items.angsuran_2.tanggal_1)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 2
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_2 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_2
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_2, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_2
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_2 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_2
                                ?  <>{formatDate(items.angsuran_2.tanggal_2)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 3
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_3 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_3
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_3, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_3
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_3 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_3
                                ?  <>{formatDate(items.angsuran_2.tanggal_3)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 4
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_4 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_4
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_4, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_4
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_4 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_4
                                ?  <>{formatDate(items.angsuran_2.tanggal_4)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 5
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_5 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_5
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_5, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_5
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_5 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_5
                                ?  <>{formatDate(items.angsuran_2.tanggal_5)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 6
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_6 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_6
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_6, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_6
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_6 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_6
                                ?  <>{formatDate(items.angsuran_2.tanggal_6)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 7
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_7 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_7
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_7, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_7
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_7 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_7
                                ?  <>{formatDate(items.angsuran_2.tanggal_7)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 8
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_8 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_8
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_8, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_8
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_8 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_8
                                ?  <>{formatDate(items.angsuran_2.tanggal_8)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 9
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_9 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_9
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_9, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_9
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_9 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_9
                                ?  <>{formatDate(items.angsuran_2.tanggal_9)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 10
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_10 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_10
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_10, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_10
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_10 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_10
                                ?  <>{formatDate(items.angsuran_2.tanggal_10)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 11
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_11 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_11
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_11, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_11
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_11 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_11
                                ?  <>{formatDate(items.angsuran_2.tanggal_11)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 12
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_12 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_12
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_12, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_12
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_12 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_12
                                ?  <>{formatDate(items.angsuran_2.tanggal_12)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 13
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_13 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_13
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_13, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_13
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_13 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_13
                                ?  <>{formatDate(items.angsuran_2.tanggal_13)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 14
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_14 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_14
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_14, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_14
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_14 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_14
                                ?  <>{formatDate(items.angsuran_2.tanggal_14)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 15
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_15 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_15
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_15, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_15
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_15 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_15
                                ?  <>{formatDate(items.angsuran_2.tanggal_15)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 16
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_16 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_16
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_16, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_16
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_16 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_16
                                ?  <>{formatDate(items.angsuran_2.tanggal_16)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 17
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_17 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_17
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_17, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_17
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_17 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_17
                                ?  <>{formatDate(items.angsuran_2.tanggal_17)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 18
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_18 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_18
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_18, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_18
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_18 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_18
                                ?  <>{formatDate(items.angsuran_2.tanggal_18)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 19
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_19 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_19
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_19, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_19
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_19 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_19
                                ?  <>{formatDate(items.angsuran_2.tanggal_19)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 20
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_20 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_20
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_20, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_20
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_20 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_20
                                ?  <>{formatDate(items.angsuran_2.tanggal_20)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 21
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_21 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_21
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_21, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_21
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_21 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_21
                                ?  <>{formatDate(items.angsuran_2.tanggal_21)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 22
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_22 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_22
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_22, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_22
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_22 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_22
                                ?  <>{formatDate(items.angsuran_2.tanggal_22)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 23
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_23 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_23
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_23, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_23
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_23 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_23
                                ?  <>{formatDate(items.angsuran_2.tanggal_23)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card rounded-3 shadow border-0 h-100">
                    <div className="card-header bg-primary shadow d-flex justify-content-between">
                      <div>
                        <h5 className="text-white small fw-normal my-0">
                          <i className="fa fa-fw fa-dollar small"></i>{" "}
                          Pembayaran 24
                        </h5>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        {items.angsuran_2.pembayaran_24 ?
                        <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                        : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                      </div>
                    </div>
                    <div className="card-body pb-0">
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Jumlah
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.pembayaran_24
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.pembayaran_24, 0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Denda
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.denda_24
                                ?  <>Rp. {getCurrency(
                                  items.angsuran_2.denda_24 * 5000,
                                  0
                                )}</>
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-0 text-dark bg-none small">
                              Tanggal
                            </td>
                            <td className="border-0 text-dark bg-none small">
                              :
                            </td>
                            <td className="border-0 text-dark fw-semibold bg-none small">
                              {items.angsuran_2.tanggal_24
                                ?  <>{formatDate(items.angsuran_2.tanggal_24)}
                                </>
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                </>
              )}

              {items.angsuran_1 === null && items.angsuran_2 === null &&
                (
                  <div className="rounded-4 pt-0 pb-4">
                    <div className="text-center p-5">
                      <i className="fa fa-fw fa-circle-exclamation text-secondary fs-3 mb-2 pt-5"></i>
                      <p className="my-0 text-secondary small pb-5">
                        Saat ini data angsuran masih kosong, data akan ditampilkan apabila sudah melakukan pembayaran.
                      </p>
                    </div>
                  </div>
                )
              }
            </div>
          </div>

          {items.angsuran.length > 0 &&
            <>
              <div className="rounded-4 mx-3 p-3">
                <h1 className="fw-semibold text-center py-2 my-0 fs-5">Angsuran Opsional</h1>
              </div>

              <div className="mx-0 px-3 pb-3 pt-0">
                <div className="row row-size px-2 gy-3 gx-2">
                  {console.log(items.angsuran)}
                  {items.angsuran.map((a, index) => {
                    return (
                      <div className="col-lg-3" key={index}>
                        <div className="card rounded-3 shadow border-0 h-100">
                          <div className="card-header bg-primary shadow d-flex justify-content-between">
                            <div>
                              <h5 className="text-white small fw-normal my-0">
                                <i className="fa fa-fw fa-dollar small"></i>{" "}
                                Pembayaran {index + 1}
                              </h5>
                            </div>
                            {/* <div className="d-flex justify-content-center align-items-center">
                              {items.angsuran_2.pembayaran_24 ?
                              <i className="fa fa-fw fa-solid fa-check text-green-light bg-dark rounded shadow-nice"></i>
                              : <i className='fa fa-fw fw-light fa-clock text-gray-500 bg-dark rounded shadow-nice'></i> }
                            </div> */}
                          </div>
                          <div className="card-body pb-0">
                            <table className="table table-sm">
                              <tbody>
                                <tr>
                                  <td className="border-0 text-dark bg-none small">
                                    Jumlah
                                  </td>
                                  <td className="border-0 text-dark bg-none small">
                                    :
                                  </td>
                                  <td className="border-0 text-dark fw-semibold bg-none small">
                                    {a.jumlah ? `Rp. ${getCurrency(a.jumlah, 0)}` : '-'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border-0 text-dark bg-none small">
                                    Denda
                                  </td>
                                  <td className="border-0 text-dark bg-none small">
                                    :
                                  </td>
                                  <td className="border-0 text-dark fw-semibold bg-none small">
                                    {a.denda ? `Rp. ${getCurrency(a.denda, 0)}` : '-'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border-0 text-dark bg-none small">
                                    Tanggal
                                  </td>
                                  <td className="border-0 text-dark bg-none small">
                                    :
                                  </td>
                                  <td className="border-0 text-dark fw-semibold bg-none small">
                                    {a.tanggal ? formatDate(a.tanggal) : '-'}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            </>
          }
        </>
      ) : (
        <Loading text="Loading. . ." />
      )}
    </>
  );
};

export default Pembayaran;
