import React, { useState } from "react";
import LoginAs from "../../components/layouts/LoginAs";
import Loading from "../../components/Loading";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect } from "react";
import { titleState } from "../../storages";
import { fetchBukuSakuKegiatan, postBukuSaku } from "../../api";
import { tokenState, userState } from "../../storages/auth";
import { getBulan, saAlert } from "../../helpers";
import { Modal } from "react-bootstrap";
import { useRef } from "react";
import Select from "../../components/Select";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import { useCallback } from "react";

function BukuSaku() {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const user = useRecoilValue(userState);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => setTitle("Buku Saku"), [setTitle]);

  const [kegiatanBukuSaku, setKegiatanBukuSaku] = useState({});

  const fetchData = useCallback(async () => {
    const res = await fetchBukuSakuKegiatan("all", token);
    setKegiatanBukuSaku(res.data.buku_saku_kegiatan);
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchBukuSakuKegiatan("all", token);
        setKegiatanBukuSaku(res.data.buku_saku_kegiatan);
        setIsLoaded(true);
        // console.log(res.data.buku_saku_kegiatan);
        // console.log(user.id);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [token]);

  if (isLoaded) {
    return (
      <>
        <div className="bg-white shadow rounded-3 mx-2 pt-3">
          <LoginAs />

          <div className="row g-0">
            <div className="col-lg-12 mt-3">
              <div className="rounded-3">
                <div className="shadow-nice score-background rounded-3 p-4">
                  <div className="row gx-0 gy-2">
                    <div className="col-lg-12">
                      <div className="text-center text-warning border-bottom border-secondary pb-3 fw-semibold fs-5">
                        Buku Saku
                      </div>
                      <div className="rounded-bottom pt-3">
                        <ul className="text-white mb-0">
                          <li className="text-sm">
                            Sebagai mahasiswa Kampus Alfa Prima{" "}
                            <span className="text-warning text-sm">wajib</span>{" "}
                            mengikuti kegiatan yang sudah tercantum pada buku
                            saku, kegiatan yang tercantum di buku saku ini
                            sebagai syarat kesiapan menuju{" "}
                            <span className="text-warning text-sm">
                              dunia kerja
                            </span>{" "}
                            dan{" "}
                            <span className="text-warning text-sm">
                              kelulusan
                            </span>{" "}
                            di Kampus Alfa Prima.
                          </li>
                          <li className="text-sm">
                            Jadwal pelaksanaan kegiatan dapat berubah{" "}
                            <span className="text-warning text-sm">
                              sewaktu-waktu
                            </span>{" "}
                            menyesuaikan dengan kondisi, pastikan selalu melihat
                            pengumuman.
                          </li>
                          <li className="text-sm">
                            Mahasiswa dapat mengikuti kegiatan{" "}
                            <span className="text-warning text-sm">
                              seminar
                            </span>{" "}
                            dan{" "}
                            <span className="text-warning text-sm">
                              workshop digital
                            </span>{" "}
                            yang diadakan kampus maupun yang diadakan diluar
                            kampus.
                          </li>
                          <li className="text-sm">
                            Mahasiswa yang telah mengikuti atau melaksanakan
                            kegiatan dibuktikan dengan menunjukan{" "}
                            <span className="text-warning text-sm">
                              sertifikat
                            </span>
                            ,{" "}
                            <span className="text-warning text-sm">piagam</span>
                            , <span className="text-warning text-sm">SK</span>,
                            maupun{" "}
                            <span className="text-warning text-sm">foto</span>{" "}
                            pelaksanaan kegiatan.
                          </li>
                          <li className="text-sm">
                            <span className="text-warning text-sm">PIC</span>{" "}
                            Bertugas untuk mengecek ttd dan bukti pelaksaan
                            kegiatan.
                          </li>
                          <li className="text-sm">
                            Mahasiswa dapat berdiskusi dengan bagian{" "}
                            <span className="text-warning text-sm">
                              Akademik
                            </span>{" "}
                            maupun{" "}
                            <span className="text-warning text-sm">
                              Pusat Karir
                            </span>{" "}
                            mengenai hal-hal yang belum dipahami.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-4 mx-3 p-3">
          <h1 className="fw-semibold text-center py-2 my-0 fs-5">List Kegiatan</h1>
        </div>

        <div className="bg-white shadow rounded-3 mx-2 px-3 pb-3 pt-2">
          <div className="row row-size px-2 gy-3 gx-2">
            {kegiatanBukuSaku.length > 0 &&
            kegiatanBukuSaku.map((bsk, index) => {
              return (
                <div className='col-lg-4' key={index}>
                    <div className="rounded border-0 bg-primary text-white shadow-nice me-1 h-100">

                        {bsk.buku_saku.filter((bs) => parseInt(bs.mhs_id) === parseInt(user.id)).length > 0 ? (
                          <>
                            {bsk.buku_saku.filter((bs) => parseInt(bs.status) === 1 && parseInt(bs.mhs_id) === parseInt(user.id)).map((bs, index) => {
                              return (
                                <div key={index}>
                                  <div className='position-relative bg-primary shadow-nice rounded-top p-3'>
                                    <h6 className="mb-0 text-white" style={{marginRight: '2.5rem'}}>{bsk.judul}</h6>
                                    <div className='position-absolute bg-primary p-3 rounded-pill' style={{top: '-7px', right: '-7px',}}>
                                        <i className='align-middle fa fa-clock fa-regular fa-fade fa-xl'/>
                                    </div>
                                  </div>
                                  <div className='text-gray-500 p-3'>
                                    <p className='mb-2 text-sm text-white'><i className="fa fa-spinner fa-spin me-1"/> Menunggu persetujuan Akademik.</p>
                                    <p className='mb-2 text-sm'>Kegiatan ditetapkan untuk dilaksanakan pada bulan{" "} {getBulan(bsk.bulan)}</p>
                                    <BukuSakuCreate
                                      title='Edit form pengajuan' 
                                      buttonColor='btn-success-2'
                                      icon='fa-edit'
                                      fetchData={fetchData}
                                      kbsID={bsk}
                                      bsID={bs}
                                    />
                                  </div>
                                </div>
                              )
                            })}
                            {bsk.buku_saku.filter((bs) => parseInt(bs.status) === 2 && parseInt(bs.mhs_id) === parseInt(user.id)).map((bs, index) => {
                              return (
                                <div key={index}>
                                  <div className='position-relative bg-primary shadow-nice rounded-top p-3'>
                                    <h6 className="mb-0 text-success-light me-5">{bsk.judul}</h6>
                                    <div className='position-absolute bg-primary p-3 rounded-pill' style={{top: '-7px', right: '-7px',}}>
                                        <i className='align-middle fa fa-circle-check text-success-light fa-xl'/>
                                    </div>
                                  </div>
                                  <div className='text-gray-500 p-3'>
                                    <p className='mb-2 text-sm text-success-light'><i className="fa fa-check me-1"/> Pengajuan disetujui oleh Akademik.</p>
                                    <p className='mb-2 text-sm'>Kegiatan telah diikuti dan dilaksanakan pada bulan Januari</p>
                                    <button className='btn btn-sm disabled btn-success-2'>Sudah Mengikuti <i className='fa fa-check fa-sm ms-1'/></button>
                                  </div>
                                </div>
                              )
                            })}
                            {bsk.buku_saku.filter((bs) => parseInt(bs.status) === 9 && parseInt(bs.mhs_id) === parseInt(user.id)).map((bs, index) => {
                              return (
                                <div key={index}>
                                  <div className='position-relative bg-primary shadow-nice rounded-top p-3'>
                                    <h6 className="mb-0 text-white me-5">{bsk.judul}</h6>
                                    <div className='position-absolute bg-primary p-3 rounded-pill' style={{top: '-7px', right: '-7px',}}>
                                        <i className='align-middle fa fa-triangle-exclamation text-warning fa-xl'/>
                                    </div>
                                  </div>
                                  <div className='text-gray-500 p-3' key={index}>
                                    <p className='mb-2 text-sm text-warning'><i className="fa fa-triangle-exclamation me-1"/> Pengajuan anda ditolak oleh Akademik.</p>
                                    <p className='mb-2 text-sm'>Silahkan hubungi Akademik mengenai informasi lebih lanjut.</p>
                                    <BukuSakuCreate
                                      title='Ulangi form pengajuan' 
                                      buttonColor='btn-success-2'
                                      icon='fa-rotate'
                                      fetchData={fetchData}
                                      kbsID={bsk}
                                      bsID={bs}
                                    />
                                  </div>
                                </div>
                              )
                            })}
                            {bsk.buku_saku.filter((bs) => bs.status === null && parseInt(bs.mhs_id) === parseInt(user.id)).map((bs, index) => {
                              return (
                                <div key={index}>
                                  <div className='position-relative bg-primary shadow-nice rounded-top p-3'>
                                    <h6 className="mb-0 text-white" style={{marginRight: '2.5rem'}}>{bsk.judul}</h6>
                                    <div className='position-absolute bg-primary p-3 rounded-pill' style={{top: '-7px', right: '-7px',}}>
                                        <i className='align-middle fa fa-clock-rotate-left text-gray-500 fa-xl'/>
                                    </div>
                                  </div>
                                  <div className='text-gray-500 p-3'>
                                    <p className='mb-2 text-sm text-white'><i className="fa fa-gear me-1"/> Belum ada pengajuan.</p>
                                    <p className='mb-2 text-sm'>Kegiatan ditetapkan untuk dilaksanakan pada bulan{" "} {getBulan(bsk.bulan)}</p>
                                    <BukuSakuCreate
                                      title='Ajukan sudah Mengikuti' 
                                      buttonColor='btn-primary-2'
                                      icon='fa-paper-plane'
                                      fetchData={fetchData}
                                      kbsID={bsk}
                                      bsID={bs}
                                    />
                                  </div>
                                </div>
                              )
                            })}
                          </>
                        ) : (
                          <div key={index}>
                            <div className='position-relative bg-primary shadow-nice rounded-top p-3'>
                              <h6 className="mb-0 text-white" style={{marginRight: '2.5rem'}}>{bsk.judul}</h6>
                              <div className='position-absolute bg-primary p-3 rounded-pill' style={{top: '-7px', right: '-7px',}}>
                                  <i className='align-middle fa fa-clock-rotate-left text-gray-500 fa-xl'/>
                              </div>
                            </div>
                            <div className='text-gray-500 p-3'>
                              <p className='mb-2 text-sm text-white'><i className="fa fa-gear me-1"/> Belum ada pengajuan.</p>
                              <p className='mb-2 text-sm'>Kegiatan ditetapkan untuk dilaksanakan pada bulan{" "} {getBulan(bsk.bulan)}</p>
                              <BukuSakuCreate
                                title='Ajukan sudah Mengikuti' 
                                buttonColor='btn-primary-2'
                                icon='fa-paper-plane'
                                fetchData={fetchData}
                                kbsID={bsk}
                              />
                            </div>
                          </div>
                        )}
                        
                    </div>
                </div>
              ) 
            })
            }
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Loading/>
      </>
    )
  }
}

export const BukuSakuCreate = (props) => {
  const {fetchData, kbsID, bsID = {}, title, buttonColor, icon} = props
  const user = useRecoilValue(userState);
  const token = useRecoilValue(tokenState);
  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [form1, setForm1] = useState(bsID);
  const [form2, setForm2] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setForm1(bsID);
    // console.log(bsID)
    setModal(true);
    setForm2({
      mhs_id: `${user.id}`,
      buku_saku_kegiatan_id: `${kbsID.id}`,
    })
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setForm1({});
    setForm2({});
    setErrors({});
  };

  const handleChange = (e) => {
    setForm1((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const allForm = {...form1, ...form2}

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    // console.log(allForm)

    // setTimeout(async() => {
      
    // }, 1000);

    try {
      const res = await postBukuSaku(allForm, token);
      // console.log(res.data)
      if (res.data && res.data.msg) {
        fetchData();
        handleClose();
        saAlert(res.data.msg, res.data.msg, 'Berhasil melakukan perubahan.');
      }
    } catch (err) {
      err.response && err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <>
      <button onClick={handleOpen} className={`btn btn-sm ${buttonColor}`}>{title} <i className={`fa ${icon} fa-sm ms-1`}/></button>

      <Modal
        show={modal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
        contentClassName="border-0 rounded-4"
      >
        <Modal.Header 
          data-bs-theme='dark' 
          className="border-0 score-background shadow rounded-top-4" 
          closeButton
        >
          <Modal.Title className="fs-5 text-white">{kbsID.judul}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} ref={tagForm}>
            <div className="row">
              <div className="col-lg-6">
                {form1.status === 9 ? (
                  <div>
                    <p className="mb-0 text-danger"><i className="fa fa-triangle-exclamation me-1"/> Pengajuan anda ditolak oleh Akademik.</p>
                    <p className='mb-2 text-sm'>Silahkan hubungi Akademik mengenai informasi lebih lanjut.</p>
                  </div>
                ) : (
                  <Select
                    label="Status"
                    name="status"
                    onChange={handleChange}
                    value={form1.status === null ? '' : form1.status}
                    error={errors.status}
                  >
                    <option value="">Pilih</option>
                    <option value={1}>Sudah Mengikuti</option>
                    <option value="">Belum Mengikuti</option>
                  </Select>
                )

                }
              </div>
              <div className="col-lg-6">
                <Input
                  label="Tanggal"
                  type="date"
                  name="tanggal"
                  value={form1.tanggal}
                  onChange={handleChange}
                  error={errors.tanggal}
                />
              </div>
              <div className="col-lg-12">
                <Textarea
                  label="Keterangan"
                  name="keterangan"
                  value={form1.keterangan}
                  onChange={handleChange}
                  error={errors.keterangan}
                />
              </div>
            </div>
            <div className="btn-group mobile-justify-center rounded-3 mt-3">
              <button type="submit" className="btn btn-sm btn-primary-2 text-white">
                Simpan
              </button>
              <button type="button" className="btn btn-sm btn-dark text-white" onClick={handleClose}>
                Kembali
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BukuSaku;
