import config from "axios";
import { getOrigin, saToast } from "../helpers";

const axios = config.create({
  baseURL: getOrigin(),
});

export const fetchingData = (
  icon = "success",
  title = "Loading, please wait !"
) => {
  saToast(icon, title);
};

export const headers = (token) => {
  return { headers: { Authorization: `Bearer ${token}` } };
};

export const loginAuth = (data) => {
  fetchingData();
  return axios.post("/api/login", data);
};

export const checkAuth = (token) => {
  return axios.get("/api/check-mhs", headers(token));
};

// Logout
export const postLogout = (token) => {
  fetchingData();
  return axios.post(`/api/logout`, null, headers(token));
};

// KRS
// index
export const fetchKrs = (page = 1, token, custom = "") => {
  return axios.get(`/api/krs?page=${page}&${custom}`, headers(token));
};

// Kelas
// show
export const showKelas = (id, token, custom = "") => {
  return axios.get(`/api/kelas/${id}?${custom}`, headers(token));
};

// Profile
export const showProfile = (id, token, custom = "") => {
  return axios.get(`/api/mhs/${id}?${custom}`, headers(token));
};
// patch
export const patchProfile = (id, data, token) => {
  fetchingData();
  return axios.patch(`/api/mhs/${id}`, data, headers(token));
};

// ks
// store
export const postKs = (data, token, custom = "") => {
  fetchingData();
  return axios.post(`/api/ks?${custom}`, data, headers(token));
};

// penilaian dosen
// store
export const postPenilaianDosen = (data, token, custom = "") => {
  fetchingData();
  return axios.post(`/api/penilaian-dosen?${custom}`, data, headers(token));
};

// index
export const fetchProgkam = (page = 1, token, custom = "") => {
  return axios.get(`/api/progkam?page=${page}&${custom}`, headers(token));
};

// buku perpustakaan
export const fetchBukuPerpustakaan = (page = 1, token, custom = "") => {
  return axios.get(
    `/api/buku-perpustakaan?page=${page}&${custom}`,
    headers(token)
  );
};

// mhs_pojt
// show
export const showMhsPojt = (id, token, custom = "") => {
  return axios.get(`/api/mhs_pojt/${id}?${custom}`, headers(token));
};

// patch
export const patchPojt = (id, data, token) => {
  fetchingData();
  return axios.patch(`/api/mhs_pojt/${id}`, data, headers(token));
};

// biaya_optional
// show
export const showAngsuran = (id, token, custom = "") => {
  return axios.get(`/api/angsuran/${id}?${custom}`, headers(token));
};

// Peminjaman Ruangan
// index
export const fetchRuangan = (page = 1, token, custom = "") => {
  return axios.get(`/api/ruangan?page=${page}&${custom}`, headers(token));
};

// Peminjaman Ruangan
// index
export const fetchPeminjamanRuangan = (page = 1, token, custom = "") => {
  return axios.get(
    `/api/peminjaman-ruangan?page=${page}&${custom}`,
    headers(token)
  );
};

// store
export const postPeminjamanRuangan = (data, token, custom = "") => {
  fetchingData();
  return axios.post(`/api/peminjaman-ruangan?${custom}`, data, headers(token));
};

// patch
export const patchPeminjamanRuangan = (id, data, token) => {
  fetchingData();
  return axios.patch(`/api/peminjaman-ruangan/${id}`, data, headers(token));
};

// get pengumuman
export const fetchPengumuman = (page = 1, token, custom = "") => {
  return axios.get(`/api/pengumuman?page=${page}&${custom}`, headers(token));
};

// get buku saku kegiatan
export const fetchBukuSakuKegiatan = (page = 1, token, custom = "") => {
  return axios.get(`/api/buku-saku-kegiatan?page=${page}&${custom}`, headers(token));
};

// get buku saku
export const fetchBukuSaku = (page = 1, token, custom = "") => {
  return axios.get(`/api/buku-saku?page=${page}&${custom}`, headers(token));
};

// post buku saku
export const postBukuSaku = (data, token, custom = "") => {
  fetchingData();
  return axios.post(`/api/buku-saku?${custom}`, data, headers(token));
};

// get berita terkini
export const fetchBeritaTerkini = (page = 1, token, custom = "") => {
  return axios.get(`/api/berita-terkini?page=${page}&${custom}`, headers(token));
};

// store
export const postBeritaTerkini = (data, token, custom = "") => {
  fetchingData();
  return axios.post(`/api/berita-terkini?${custom}`, data, headers(token));
};

// patch
export const patchBeritaTerkini = (id, data, token) => {
  fetchingData();
  return axios.patch(`/api/berita-terkini/${id}`, data, headers(token));
};

// show berita terkini
export const showBeritaTerkini = (id, token, custom = "") => {
  return axios.get(`/api/berita-terkini/${id}?${custom}`, headers(token));
};

// get survey mahasiswa
export const fetchSurveyMahasiswa = (page = 1, token, custom = "") => {
  return axios.get(`/api/survey?page=${page}&${custom}`, headers(token));
};

// get soal survey mahasiswa
export const fetchSoalSurveyMahasiswa = (page = 1, token, custom = "") => {
  return axios.get(`/api/soal-survey?page=${page}&${custom}`, headers(token));
};

// show soal survey mahasiswa
export const showSoalSurveyMahasiswa = (id, token, custom = "") => {
  return axios.get(`/api/soal-survey/${id}?${custom}`, headers(token));
};

// store mhs soal survey
export const postMhsSoalSurvey = (data, token, custom = "") => {
  fetchingData();
  return axios.post(`/api/mhs-soal-survey?${custom}`, data, headers(token));
};